<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="grid"
        :title="title"
        tableId="grid"
        :columnSetting="false"
        :isFullScreen="false"
        :columns="grid.columns"
        :filtering="false"
        :data="grid.data"
        selection="multiple"
        rowKey="envAirDailyResultPreventiveHistoryId"
        :isExcelDown="false"
        :editable="editable"
      >
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn label="LBLADD" v-if="editable" icon="add" @btnClicked="addrow1" />
            <c-btn label="LBLREMOVE" v-if="editable" icon="remove" @btnClicked="removeRow1" />
            <c-btn label="LBLSAVE" v-if="editable" icon="save" @btnClicked="saveRow1" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
  </div>
</template>
<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'gov-schedule-info',
  props: {
    param: {
      type: Object,
      default: () => ({
        envAirMstPreventiveId: '',
      }),
    },
  },
  data() {
    return {
      title: '',
      grid: {
        columns: [
          {
            name: 'repairPeriod',
            field: 'repairPeriod',
            label: '보수기간',
            align: 'center',
            type: 'date',
            range: true,
            style: 'width:250px',
            required: true,
            sortable: true,
          },
          {
            name: 'userName',
            field: 'userName',
            label: '보수자',
            align: 'center',
            type: 'user',
            userId: 'userId',
            style: 'width:120px',
            required: true,
            sortable: true,
          },
          {
            name: 'remark',
            field: 'remark',
            label: '보수명세',
            align: 'left',
            type: 'text',
            sortable: true,
          },
        ],
        data: [],
      },
      nowplantCd: '',
      detailUrl: '',
      listUrl: '',
      insertUrl: '',
      deleteUrl: '',
      editable: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // api scope
      this.detailUrl = selectConfig.env.air.mst.preventive.get.url;
      this.listUrl = selectConfig.env.air.daily.preventive.history.url;
      this.insertUrl = transactionConfig.env.air.daily.preventive.history.save.url;
      this.deleteUrl = transactionConfig.env.air.daily.preventive.history.delete.url;

      this.getDetail();
      this.getList();
    },
    getDetail() {
      if (this.param.envAirMstPreventiveId) {
        this.$http.url = this.detailUrl;
        this.$http.param = {envAirMstPreventiveId: this.param.envAirMstPreventiveId}
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.nowplantCd = _result.data.plantCd;
          this.title = '방지시설 보수이력 ('+_result.data.envAirMstPreventiveName+')'
        },);
      }
    },
    rowRemoveSelect() {
      var _table = document.getElementsByClassName('bg-light-blue-1')[0];
      if (_table) {
        _table.classList.remove('bg-light-blue-1');
      }
      var _table2 = document.getElementsByClassName('bg-light-blue-1')[1];
      if (_table2) {
        _table2.classList.remove('bg-light-blue-1');
      }
    },
    getEnvList() {
      this.getList();
      this.reset();
    },
    reset() {
      this.rowRemoveSelect();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {envAirMstPreventiveId: this.param.envAirMstPreventiveId};
      this.$http.request((_result) => {
        _result.data = this.$_.map(_result.data, (item) => {
          return this.$_.extend(item, {
            repairPeriod: [item.startDt, item.endDt],
          })
        })
        this.grid.data = _result.data;
      },);
    },
    addrow1() {
      this.rowRemoveSelect();
      this.grid.data.splice(0, 0, {
        plantCd: this.nowplantCd,  // 사업장코드
        envAirMstPreventiveId: this.param.envAirMstPreventiveId,  // 대기배출구(방지시설) 일련번호
        envAirDailyResultPreventiveHistoryId: uid(),  // 방지시설별 부품 일련번호
        startDt: '',
        endDt: '',
        repairPeriod: [],
        userId: '',
        remark: '',
        editFlag: 'C',
        regUserId: this.$store.getters.user.userId
      });
    },
    removeRow1() {
      let selectData = this.$refs['grid'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGREMOVE', // 삭제하시겠습니까?,
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.deleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.grid.data = this.$_.reject(this.grid.data, item);
              })
              this.$refs['grid'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveRow1() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        let saveData = this.grid.data.filter( x => {
          return x.editFlag == 'C' || x.editFlag == 'U'
        });
        saveData = this.$_.map(saveData, (item) => {
          return this.$_.extend(item, {
            startDt: item.repairPeriod[0],
            endDt: item.repairPeriod[1],
          })
        })
        if(saveData.length > 0) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            type: 'info',
            // 확인 callback 함수
            confirmCallback: () => {
              this.$http.url = this.insertUrl;
              this.$http.type = 'POST';
              this.$http.param = saveData;
              this.$http.request(() => {
                window.getApp.$emit('APP_REQUEST_SUCCESS');
                this.getEnvList();
              });
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('ALERT', {
            title: '안내 ', /* 안내 */
            message: 'MSGNOCHANGEDATA', // 변경된 데이터가 없습니다., /* 변경된 데이터가 없습니다. */
            type: 'info', // success / info / warning / error
          });
        }
      }
    },
  }
};
</script>